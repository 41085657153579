import { NavItem } from "./theme"

export const getMobileNavItems = (session) => {
  return [
    [
      {
        label: `${session.first_name} ${session.last_name}`,
        userName: true,
      },
      {
        label: `${session.email}`,
      },
    ],
    [
      {
        label: "Logout",
        href: "/api/auth/logout",
      },
    ],
  ]
}

export const navItems: Array<NavItem> = [
  {
    label: "Dashboard",
    href: "/",
  },
  {
    label: "User Analytics",
    href: "/analytics/users",
  },
]
